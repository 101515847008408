<template>
  <div class="all">
    <AppHeader active-index="/consultant"></AppHeader>
    <div class="main">
      <h3 style="margin-bottom: 20px;">专家顾问</h3>

      <el-row :gutter="50" v-for="(expert, index) of experts" :key="index" style="margin-top: 30px">
        <el-col :span="8" v-for="item of expert" :key="item.id">
          <!--                <a  target="_blank" :href="item.url">-->
          <router-link :to="{name:'Expert', query:{ id:item.id , src:item.src , title:item.title , desc:item.desc,content:item.content }}">
            <div class="shadow" style="border: 1px solid #e5e5e5;border-radius: 4px;transition: .3s;">
              <el-image :src="item.src" lazy style="display: block;width: 100%"/>
              <div style="background-color: #f1f1f1;border-top: 1px solid #e5e5e5">
                <h3 style="padding: 8px">{{ item.title }}</h3>
                <p style="padding: 8px">擅长分类：{{ item.desc }}</p>
              </div>
            </div>
          </router-link>
          <!--                </a>-->
        </el-col>
      </el-row>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/Footer";
import AppHeader from "../../components/AppHeader";

export default {
  name: "Consultant",
  data() {
    return {
      allProjects: [
        {
          id: '0',
          title: '徐明',
          desc: '运动健康',
          src: require('../../assets/App/person1.jpg'),
          content: "成都体育学院运动医学与健康学院人体科学专业及体育系体育教育专业硕士研究生导师；\n" +
              "吉林大学珠海学院体育科学学院副院长；\n" +
              "四川省首批学术和技术带头人后备人选，科技部、教育部专家库成员；\n" +
              "国家体育总局教练员科学训练讲师团成员；\n" +
              "西藏自治区社会体育指导员协会顾问；\n" +
              "中国健美操协会委员。"
        },
        {
          id: '1',
          title: '王明',
          desc: '运动健康',
          src: require('../../assets/App/person2.jpg'),
          content: "吉林大学珠海学院体育教研室主任，担任田径队、排球队主教练；\n" +
              "华南师范大学体育教育与训练专业研究生；\n" +
              "中华人民共和国田径一级裁判员；\n" +
              "中华人民共和国篮球二级裁判员；\n" +
              "中华人民共和国排球二级裁判员；\n" +
              "高等学校教师资格；\n" +
              "体验教育高级教练；\n" +
              "一级社会指导员（太极拳）；\n" +
              "主编教材3部、副主编教材2部、发表学术论文12篇，课题结题2项。\n" +
              "教学特长：篮球、游泳、散打、太极拳、户外拓展、田径十项全能、网球、足球。"
        },
        {
          id: '2',
          title: '刘书芳',
          desc: '运动康复',
          src: require('../../assets/App/person3.jpg'),
          content: "运动康复博士，硕士生导师，副教授，广州体育学院运康复教研室主任；\n" +
              "广东省康复医学会运动与创伤康复分会副会长，广东省康复医学会物理治疗师分会常务理事；\n" +
              "广东省体能协会副秘书长，广东省体育运动与健康协会理事；\n" +
              "中国康复医学会康复医学教育专业委员会委员；\n" +
              "中国老年保健医学研究会老龄服务及标准化分会委员；\n" +
              "国家体育总局乒羽中心特聘讲师（主讲运动损伤预防与康复），广东省体能协会特聘讲师（主讲青少年运动损伤防治）。"
        },
        {
          id: '3',
          title: '林文弢',
          desc: '运动健康',
          src: require('../../assets/App/person4.jpg'),
          content: "广州体育学院运动生物化学二级教授，上海体育学院博士生导师，享受国务院特殊津贴专家；\n" +
              "现任体医结合研究所所长，运动生物化学广东省重点教学实验室主任；\n" +
              "国家特色专业建设点运动人体科学专业带头人，天津体育学院等高校客座教授；\n" +
              "中国体育科学学会运动医学专业委员会和运动生理生化专业委员会常委；\n" +
              "广东省体育运动与健康协会会长；\n" +
              "获20多项专利（其中4项发明专利），公开发表论文200多篇，主编和参编20部教材和专著。"
        },
        {
          id: '4',
          title: '贾玲',
          desc: '运动健康',
          src: require('../../assets/App/person5.jpg'),
          content: "原吉林省体工队退役运动员；\n" +
              "国家健将级运动员；\n" +
              "现就职吉林大学珠海学院；\n" +
              "主抓校田径队短跨项目；\n" +
              "曾多次带领学生参加大型比赛；\n" +
              "曾带领团队获得广东省田径锦标赛男子100m、200m第一名；\n" +
              "广东田径运动会男子100m第一名；\n" +
              "全国大学生田径比赛男子100m、200m冠军。"
        },
        {
          id: '5',
          title: '贺冬青',
          desc: '运动健康',
          src: require('../../assets/App/person6.jpg'),
          content: "广州体育学院学生；\n" +
              "广东省大众艺术体操联赛第一名；\n" +
              "茗星啦啦队篮球宝贝；\n" +
              "国际篮联篮球世界杯篮球宝贝广州赛区亚军；\n" +
              "曾参与中国女篮、斯杯、CBA赛事演出。"
        },
      ]
    }
  },
  methods: {},
  computed: {
    experts() {
      const experts = []
      this.allProjects.forEach((item, index) => {
        const expert = Math.floor(index / 3)//3代表3条为一行，随意更改
        if (!experts[expert]) {
          experts[expert] = []
        }
        experts[expert].push(item)
      })
      return experts
    }
  },
  components: {
    AppHeader, Footer
  },
  props: {
    activeIndex: {
      type: String
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.shadow:hover {
  /*transform: scale(1.05);*/
  box-shadow: 0 0 8px 3px #d7d7d7;
}

.all {
  min-width: 960px;
}

.main {
  margin: 110px 10% 50px 10%;
  text-align: left;
}

a, a:hover, a:active, a:visited, a:link, a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
  background: none;
  text-decoration: none;
  color: #3e3e3e;
}
</style>